import React  from 'react';
import RewardBarChart from '../components/RewardBarChart'
import Address from '../components/Address'
import Params from "../elements/Params";

export default function AccountHistory() {
    return (
        <div>
            <Address />
            <RewardBarChart />
        </div>
    );
}
