export enum LOADING_SOURCE {
    CHK_ADDRESS,
    LOAD_REWARD,
    LOAD_PIE_CHART,
    LOAD_BAR_CHART,
    LOAD_HISTORY,
    LOAD_BALANCE,
    LOAD_CONFIG,
    SAVE_CONFIG,
}

export enum ERROR_TYPE {
    NETWORK
}

export enum Types {
    CB = "Coinbase",
    COM = "Commission",
    RWD = "Rewards",
}

export enum ASSET_CAT {
    VAULT = "VAULT",
    UTXO = "UTXO",
    TOKEN = "TOKEN",
    LP = "LP",
}

export enum VAULT_CAT {
    COLLATERAL = "COLLATERAL",
    LOAN = "LOAN",
    INTEREST = "INTEREST",
}

export enum VALUE_CALCULATION {
    OPEN,
    CLOSE,
    AVG,
}

