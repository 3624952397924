import React from 'react';




export default function FAQ() {
    return (

        <div>
            <div className="Box">
                <div className="BoxHeader Headline">
                    I am using my address from CAKE (or any other exchange or custodial service)
                    but no tokens and/or rewards are shown on DFI.TAX?
                </div>
                <div className="BoxContent TEXT_2">
                    <p>
                        Unfortunately addresses from custodial services (like CAKE) won't work with DFI.TAX.
                        We can only access data for an address that exists native on the Defi Blockchain.
                    </p>
                    <p>
                        When using a custodial service, you use the address only to send your funds to the service;
                        all the "magic" like LM and staking happens in the "background". They probably combine all LP
                        Tokens on one address, where only the custodial service knows to whom which amount of the
                        rewards belong.
                    </p>
                    <p>
                        The same happens when you stake with CAKE. They need to combine the funds from different
                        customers to reach 20.000 DFI and run a Masternode, they then internally distribute the rewards
                        "virtually" to your address.
                    </p>
                    <p>
                        Only if you transfer your rewards or funds from CAKE to another address, it will be written
                        again on the native Defi Blockchain.
                    </p>
                    <p>
                        I hope that someday in the future CAKE will provide an API to access this "virtual" data.
                        If that happens we will find a way to integrate the data into DFI.TAX.
                    </p>
                </div>
                <div className="BoxFooter"/>
            </div>
        </div>


    );
}