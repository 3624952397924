import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from "./pages/Home";
import AccountReward from "./pages/AccountReward";
import Changelog from "./pages/Changelog";
import About from "./pages/About";
import AccountHistory from "./pages/AccountHistory";
import AccountDiagram from './pages/AccountDiagram';
import AccountExport from './pages/AccountExport';
import Config from './components/Config';
import FAQ from './pages/FAQ';
import {Alert, AlertTitle, Collapse} from "@mui/material";
import {useGlobalContext} from "./context/Global";
import AK from "./components/AK";
import Params from "./elements/Params";
import Donate from "./pages/Donate";


export default function Main() {
    const {isLoading, showError, clearError} = useGlobalContext();

    return (
        <>
            <Collapse in={showError}>
                <Alert className="ErrorBox" severity="error" onClose={() => clearError()}>
                    <AlertTitle>Error Loading Content</AlertTitle>
                    <p>
                        There are some issues while loading data from the backend.
                        Please try again in a few minutes.
                    </p>
                    <p>
                        If the error persists you can contact me via
                        &#160;<a href="mailto:bug@dfi.tax" target="_blank" rel="noreferrer">mail</a> or
                        &#160;<a href="https://t.me/dfi_tax" target="_blank" rel="noreferrer">telegram</a>
                    </p>
                </Alert>
            </Collapse>


            <Router>
                <div className={`Header ${isLoading() ? "IsLoading" : ""}`}>
                    <Navbar/>
                    <img src="/icon/dfi_tax_logo_write_1.png" alt="dfi" className={"Logo"}/>
                    <div className="HeaderInfo">
                        <AK/>
                    </div>

                    <div className="ConfigOption">
                        <Config/>
                    </div>
                </div>
                <div className={"Content"}>
                    <Routes>
                        <Route path='/' element={<Home/>}/>
                        <Route path='/reward/' element={<AccountReward/>}/>
                        <Route path='/history/' element={<AccountHistory/>}/>
                        <Route path='/diagram/' element={<AccountDiagram/>}/>
                        <Route path='/export' element={<AccountExport/>}/>
                        <Route path='/changelog' element={<Changelog/>}/>
                        <Route path='/faq' element={<FAQ/>}/>
                        <Route path='/donate' element={<Donate/>}/>
                        <Route path='/about' element={<About/>}/>
                        <Route path='/adr/:adr' element={<Params/>}/>
                        <Route path='/:ak' element={<Params/>}/>
                    </Routes>
                </div>
            </Router>

            <div className={"Footer"}>
                <table width={"100%"}>
                    <tbody>
                    <tr>
                        <td id="social_media">
                            <a href="https://twitter.com/dfi_tax" target="_blank"
                               rel="noreferrer">
                                <img src="social_media/twitter.png"
                                     className="social_media_icon" alt="twitter"/>
                            </a>
                            <a href="https://t.me/dfi_tax" target="_blank"
                               rel="noreferrer">
                                <img src="social_media/telegram.png" className="social_media_icon"
                                     alt="telegram"/>
                            </a>
                            <a href="https://www.youtube.com/channel/UC3A4Zg5TPgiiKJ7sw74DWCw"
                               target="_blank"
                               rel="noreferrer">
                                <img src="social_media/youtube.png" className="social_media_icon"
                                     alt="youtube"/>
                            </a>
                        </td>
                        <td id="version">{process.env.REACT_APP_VERSION} ({process.env.REACT_APP_DATE})</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}

