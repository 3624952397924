import React, {useEffect, useState} from 'react';
import {ResponsiveTreeMap} from '@nivo/treemap';
import {InfoOverlayLPDetail} from "../data/InfoOverlay";
import {FormControlLabel, Switch} from "@mui/material";
import {
    DataGridPro, DataGridProProps, GridCellParams, GridColDef, GridRowModel
} from "@mui/x-data-grid-pro";
import {useConfigContext} from "../context/Config";
import {useFormat} from "../global/Hooks";
import {useBalance} from "../da/Balance";


const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: 'Address',
    width: 450,
    cellClassName: 'BALANCE ADDRESS',
};


export default function Address() {
    const {nf, nf_dyn} = useFormat();
    const COLUMNS: GridColDef[] = [
        {
            field: 'value',
            headerName: 'value',
            cellClassName: 'BALANCE VALUE',
            align: "right",
            headerAlign: "center",
            renderCell: (data) =>
                nf_dyn(data.value),
        },
    ];
    const {config} = useConfigContext();
    const [columns, setColumns] = useState<GridColDef[]>(COLUMNS);

    console.log();
    const {
        block, data: balance, assets,
        tree_map, setting, setSetting,
    } = useBalance();

    const [block_age, setBlockAge] = useState("");

    useEffect(() => {
        const interval = setInterval(() => {
            let diff = Date.parse(new Date().toISOString()) - Date.parse(block.dt + "Z");
            let sec = (Math.floor(diff / 1000) % 60).toString().padStart(2, '0');
            let min = (Math.floor(diff / 1000 / 60) % 60).toString().padStart(2, '0');
            let hour = Math.floor(diff / 1000 / 60 / 60).toString().padStart(2, '0');
            let time_diff = hour + ":" + min + ":" + sec;
            setBlockAge(time_diff);
        }, 1000);
        return () => clearInterval(interval);
    }, [block])


    useEffect(() => {
        let col_ass: GridColDef[] = [];

        assets.forEach((ass) => {
            let cur_cfg: GridColDef = {
                field: ass.label,
                headerName: ass.label,
                cellClassName: (params) => `BALANCE TKN ${ass.vault || ass.cat}`,
                headerAlign: "center",
                align: "right",
                renderCell: (data) => nf_dyn(data.value),
            };
            col_ass.push(cur_cfg);
        });

        let cols = COLUMNS.concat(col_ass).map((column) => ({
            ...column,
            // sortable: false,
        }));
        cols[0].headerName = config?.cur_code;
        setColumns(cols);
    }, [assets]);


    const getTreeDataPath = (row: GridRowModel) => row.label;

    const getCellClassName = (params: GridCellParams) => {
        return params.rowNode.childrenExpanded ? `BALANCE HIGHLIGHT` : "";
    };

    return (
        <div className="Box">
            <div className="BoxHeader Filter">
                <div className="FilterElement">

                    <FormControlLabel
                        labelPlacement="top"
                        control={<Switch checked={setting.details} onChange={() =>
                            setSetting((set) => ({...set, details: !set.details}))}/>
                        } label={<div>LPs & UTXOs<InfoOverlayLPDetail/></div>}
                    />
                </div>
                <div className="FilterElement">
                    <FormControlLabel
                        labelPlacement="top"
                        control={<Switch checked={setting.value} onChange={() =>
                            setSetting((set) => ({...set, value: !set.value}))}/>
                        } label="Show Value"
                    />
                </div>

                <div className="FilterElement">
                    <FormControlLabel
                        labelPlacement="top"
                        control={<Switch checked={setting.tm_debit} onChange={() =>
                            setSetting((set) => ({...set, tm_debit: !set.tm_debit}))}/>
                        } label="Show Debit"
                    />
                </div>
                <div className="FilterElement">
                    <FormControlLabel
                        labelPlacement="top"
                        control={<Switch checked={setting.tm_credit} onChange={() =>
                            setSetting((set) => ({...set, tm_credit: !set.tm_credit}))}/>
                        } label="Show Credit"
                    />
                </div>

            </div>
            <DataGridPro
                columns={columns}
                autoHeight={true}
                hideFooter={true}
                rowHeight={30}
                headerHeight={40}
                getRowId={(row) => row.adr}
                //getRowClassName={(params) => params.row ? "BALANCE QTY" : ""}
                getCellClassName={getCellClassName}
                rows={balance}
                columnBuffer={100}
                treeData={true}
                getTreeDataPath={getTreeDataPath}
                groupingColDef={groupingColDef}
                defaultGroupingExpansionDepth={1}
            />
            <div className="BalanceTreeMapDebit" hidden={!setting.tm_debit}>
                <ResponsiveTreeMap
                    animate={false}
                    data={tree_map.debit}
                    valueFormat=".03s"
                    identity="name"
                    value="value"
                    colors={(entry) => entry.data.color || ""}
                    label={(node) => `${node.id}`}
                    parentLabel={(node) => `${node.id}`}
                    labelSkipSize={30} enableParentLabel={true}
                    labelTextColor="#000000"
                    parentLabelTextColor="#000000"
                    borderColor="#777777"
                    // tooltip={({node}) => (<div>XXX</div>)}
                />
            </div>
            <div className="BalanceTreeMapCredit" hidden={!setting.tm_credit}>
                <ResponsiveTreeMap
                    animate={false}
                    data={tree_map.credit}
                    valueFormat=".03s"
                    identity="name"
                    value="value"
                    colors={(entry) => entry.data.color || ""}
                    label={(node) => `${node.id}`}
                    parentLabel={(node) => `${node.id}`}
                    labelSkipSize={30}
                    labelTextColor="#000000"
                    parentLabelTextColor="#000000"
                    borderColor="#777777"
                    // tooltip={({node}) => (<div>XXX</div>)}
                />
            </div>
            <div className="BoxFooter">
                Block: {nf(block.id, 0)}
                &#160;--&#160;
                {block_age}
                {/*&#160;--&#160;*/}
                {/*{block.dt.split("T")[1]}*/}
                {/*&#160;--&#160;*/}
                {/*{block.dt.split("T")[0]}*/}
            </div>
        </div>
    );
}