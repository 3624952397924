import React from 'react';
import Params from "../elements/Params";

export default function Home() {
    return (
        <div>
            <div className="Box">
                <div className="BoxHeader Headline">
                    Welcome to DFI.TAX
                </div>
                <div className="BoxContent TEXT_2">
                    <div className="FlexOne">
                        <div>
                            Our <div className="HEADING_1">Mission</div>
                            <div className="BLOCK_1">
                                is to deliver the best (historical) data
                                from your DefiChain addresses and beyond to support you with the
                                fulfillment of your tax related obligations.
                            </div>
                        </div>
                        <div>
                            Our <div className="HEADING_1">Vision</div>
                            <div className="BLOCK_1">
                                is to provide you with an optimised and approved tax report
                                with the click of a button.
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="FlexOne">
                        <div>
                            The <div className="HEADING_1">Data</div>
                            <div className="BLOCK_1">
                                we provide is straight from the blockchain.
                                We do not rely on other APIs or projects to provide the blockchain data.
                                We use RPC calls from the official node client.
                            </div>
                            Your <div className="HEADING_1">Privacy</div>
                            <div className="BLOCK_1">
                                is your most precious asset.
                                You do not need (nor even can you) provide any personal data.<br/>
                                All data you see is publicly available.
                                We provide a random access token, to retain your information through
                                multiple session or devices.
                            </div>
                            <div className="HEADING_1">Performance</div>
                            <div className="BLOCK_1">
                                We maintaining a database with each and every block/transaction
                                and aggregated data, to deliver as fast and accurate data as possible.
                            </div>
                        </div>
                        <div>
                            <div className="HEADING_1">"Simplicity</div>
                            <div className="BLOCK_1">
                                is the ultimate sophistication".<br/>
                                We'll provide a maximum of data while keeping the interface
                                sleek and clear at the same time.
                            </div>
                            <div className="HEADING_1">Fiat Currency</div>
                            <div className="BLOCK_1">
                                rates are currently provided through different exchange APIs.
                                We plan to extract this information from the blockchain in the future.
                            </div>
                            <div className="HEADING_1">Funding</div>
                            <div className="BLOCK_1">
                                Creating and maintaining a website takes a lot of time and effort
                                and induces recurring costs for server, licencing, etc.
                                <br/>
                                DFI.tax is a community project and has been funded with a CFP. Further information
                                see <a href="./donate">Donate</a>.

                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div>
                        A word of warning: <div className="HEADING_1">Disclaimer</div>
                        <div className="BLOCK_1">
                            This project is in a very early stage. Despite our best effort,
                            we can not and will not guarantee for the accuracy or completeness
                            of the data we provide. We wont take responsibility for any damage
                            that may occur using this website. It's your responsibility review and verify
                            the data on your own.
                            <br/>
                            <div className="HEADING_1">Use at your own Risk !</div>
                        </div>
                    </div>

                </div>
                <div className="BoxFooter"/>
            </div>
        </div>
    )
        ;

}


