import * as React from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {IDropDown} from "../global/Interface";

interface IDropDownProps {
    label: string
    isDisabled?: boolean,
    current: number,
    values: IDropDown[],
    onChange: (values: number) => void,
}


export default function DropDown( props: IDropDownProps) {

    return (
        <FormControl sx={{m: 1, width: 100}}>
            <InputLabel id="aggregate-by-label">{props.label}</InputLabel>
            <Select
                labelId="aggregate-by-label"
                label={props.label}
                value={props.current}
                disabled={props.isDisabled}
                onChange={(item) => props.onChange(item.target.value as number) }>
                {props.values.map((entry) => (
                    <MenuItem key={entry.value} value={entry.value}>
                        {entry.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}