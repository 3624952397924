import {useEffect, useState} from "react";
import {IAsset, IAssetConfig, IBalance, IBalanceSheet, ITreeMap} from "../global/Interface";
import {SERVER} from "../global/Consts";
import {ASSET_CAT, LOADING_SOURCE} from "../global/Enum";
import {useGlobalContext} from "../context/Global";
import {useConfigContext} from "../context/Config";
import {useStickyState} from "../global/Hooks";

const TOTAL_STRING = 'Total';

interface ISetting {
    details: boolean,
    value: boolean,
    tm_debit: boolean,
    tm_credit: boolean
}


const DEFAULT_SETTING: ISetting = {
    details: false,
    value: false,
    tm_debit: true,
    tm_credit: true,
}

type TREE_MAPS = {
    debit: ITreeMap,
    credit: ITreeMap
}

interface IResult {
    adr: string,
    label: string[],
    value: number | undefined,

    [token: string]: number | string | string[] | undefined,
}


export function useBalance() {
    const {setLoading, isLoading, setError} = useGlobalContext();
    const {ak, config} = useConfigContext();
    const [balanceSheet, setBalanceSheet] = useState<IBalanceSheet>();
    const [data, setData] = useState<IResult[]>([]);
    const [tree_map, setTreeMap] = useState<TREE_MAPS>({debit: {}, credit: {}} as TREE_MAPS);
    const [assets, setAssets] = useState<IAssetConfig[]>([]);
    const [setting, setSetting] = useStickyState<ISetting>(DEFAULT_SETTING, "SETTING_ADDRESS")

    useEffect(() => {
        if (!ak || isLoading(LOADING_SOURCE.LOAD_BALANCE))
            return;

        const url = `${SERVER}/bal/${ak}`;
        console.log(url);
        setLoading(true, LOADING_SOURCE.LOAD_BALANCE);
        fetch(url)
            .then(result => result.json())
            .then(value => {
                setBalanceSheet(value);
                setLoading(false, LOADING_SOURCE.LOAD_BALANCE);
            }).catch(() => setError())
    }, [ak, config]);


    useEffect(() => {
        if (!balanceSheet)
            return;
        let balance = getBalance(balanceSheet.balance, setting.details);
        let assets = getAssets(balance, setting.details);
        let data = get_data(balance, assets, setting.value);

        //console.log(assets);
        setAssets(assets);
        data.sort((a, b) => a.label > b.label ? 1 : -1);
        //console.log(data);
        setData(data);
        let tree_maps: TREE_MAPS = {
            debit: getTreeMap(balanceSheet.balance, false),
            credit: getTreeMap(balanceSheet.balance, true),
        }
        setTreeMap(tree_maps);
    }, [balanceSheet, setting.details, setting.value])

    return {
        block: {id: balanceSheet?.block || '', dt: balanceSheet?.dt || ''},
        data, assets, tree_map, setting, setSetting
    };
}

function get_data(balance: IBalance[], assets: IAssetConfig[], value: boolean): IResult[] {

    let result: IResult[] = balance.map((bal) => {
        let current: IResult = {
            adr: bal.adr,
            label: [TOTAL_STRING, bal.adr],
            value: bal.assets.reduce((result, ass) => result + (ass.value || 0), 0),
        }
        if (bal.label)
            current.label.splice(1, 0, bal.label);
        bal.assets.forEach((ass) => current[ass.code] = value ? ass.value : ass.qty);
        return current;
    });


    //  calculate TOTAL
    let total_qty: IResult = {
        adr: "TOTAL_STRING",
        label: [TOTAL_STRING],
        value: result.reduce((prev, cur) => prev + cur.value!, 0)
    };
    assets.forEach((ass) => {
        total_qty[ass.label] = result.reduce((prev, cur) =>
            prev + ((cur[ass.label] as number) || 0), 0)
    });
    result.push(total_qty);

    //calculates group summary
    let labels = balance.reduce((labels, bal) => {
        if (bal.label)
            labels.add(bal.label!);
        return labels;
    }, new Set<string>());

    [...labels].forEach((label) => {
        let group_qty: IResult = {
            adr: label,
            label: [TOTAL_STRING, label],
            value: result.filter((res) => res.label.find((lab) => lab === label)).reduce((prev, cur) => prev + cur.value!, 0)
        };
        assets.forEach((ass) => {
            group_qty[ass.label] = result.filter((res) => res.label.find((lab) => lab === label)).reduce((prev, cur) =>
                prev + ((cur[ass.label] as number) || 0), 0)
        });
        result.push(group_qty);
    });

    return result;
}


function getAssets(balance: IBalance[], details: boolean): IAssetConfig[] {
    let assetConfig = getAssetConfig(balance);
    return assetConfig.filter((ass) =>
        balance.some((bal) =>
            bal.assets.some((ass1) => ass1.code === ass.code)
        ) && (ass.cat === ASSET_CAT.TOKEN || details)
    );
}


function getBalance(balance: IBalance[], details: boolean): IBalance[] {
    const get_parts = (assets: IAsset[]): IAsset[] => {
        if (!assets)
            return [];

        if (assets.length === 0)
            return [];

        if (assets.length > 1)
            return assets.map((asset) => get_parts([asset])).flat();

        if (assets[0].ass === undefined)
            return [assets[0]];

        return get_parts(assets[0].ass);
    }

    const condense = (assets: IAsset[]): IAsset[] => {
        return assets.reduce((sum: IAsset[], cur) => {
            let result = sum.find((ass) => ass.code === cur.code);
            if (result === undefined)
                sum.push({...cur});
            else {
                result.qty += cur.qty;
                result.value += cur.value;
            }
            return sum;
        }, []);
    }


    if (details) {
        return balance;
    } else {
        return balance.map((bal) =>
            ({adr: bal.adr, label: bal.label, assets: condense(get_parts(bal.assets))}));
    }
}


function getTreeMap(balance: IBalance[], loans: boolean = false): ITreeMap {
    let assetConfig = getAssetConfig(balance);
    let tokens = assetConfig.filter((ass) => ass.cat === ASSET_CAT.TOKEN);

    let result: ITreeMap = {
        name: loans ? "Credit" : "Debit",
        color: "#dddddd",
        children: tokens.map((ass) => ({name: ass.label, color: ass.color, children: []}))
    };


    const add = (token: string, label: string, cat: ASSET_CAT, value: number) => {
        let container = result.children!.find((tkn) => tkn.name === token)!;

        if (!container)
            return;

        let entry = container.children!.find((x) => x.name === label);

        if (entry)
            entry.value = entry.value! + value;
        else
            container.children!.push({name: label, color: getColorByCategory(cat), value: value});
    };

    balance.forEach((bal) => {
        bal.assets.forEach((ass) => {

            if (!ass.ass) {
                let token = ass.code;
                let label = ass.code;
                let value = ass.value;
                let cat = ass.cat;
                add(token, label, cat, value);
            } else {
                let label = ass.code;
                let cat = ass.cat;
                ass.ass.forEach((sub_ass) => {
                    let token = sub_ass.code;
                    let value = sub_ass.value;
                    add(token, label, cat, value);
                })

            }
        })
    });


    result.children?.forEach((c) =>
        c.children = c.children?.filter((cc) => loans ? cc.value! < 0 : cc.value! > 0));

    // remove tokens with no values
    result.children = result.children?.filter((c) => c.children!.length > 0);

    return result;
}

function getAssetConfig(balance: IBalance[]): IAssetConfig[] {
    let assetConfig: IAssetConfig[] = [];

    for (let b of balance) {
        for (let a of b.assets) {
            if (assetConfig.find((ac) => ac.code === a.code))
                continue;
            assetConfig.push({
                cat: a.cat,
                vault: a.vault,
                code: a.code,
                color: getColorByCategory(a.cat),
                id: 0,
                label: a.code
            });
        }
    }

    // sort by category and then by code
    assetConfig = assetConfig.sort((a, b) =>
        (Object.keys(ASSET_CAT).indexOf(a.cat) - Object.keys(ASSET_CAT).indexOf(b.cat)) * 1000
        + a.code.localeCompare(b.code));

    return assetConfig;
}

function getColorByCategory(cat: ASSET_CAT): string {
    switch (cat) {
        case ASSET_CAT.VAULT:
            return "#32858599";
        case ASSET_CAT.UTXO:
            return "#ff00af99";
        case ASSET_CAT.TOKEN:
            return "#007cff77";
        case ASSET_CAT.LP:
            return "#a5d61066";
        default:
            return "";
    }
}
