import {useEffect, useState} from "react";
import {IReward, useReward} from "./Reward";
import {useStickyState} from "../global/Hooks";
import {INTERVAL} from "../global/Consts";


export interface IRewardGrid extends IReward {
    id?: number,
}

interface IRewardDetail {
    type: boolean,
    pool: boolean,
    token: boolean,
}

const REWARD_DETAIL: IRewardDetail = {
    type: true,
    pool: false,
    token: false,
}

export function useRewardGrid() {
    const {setting, rewards, rewardState, setSetting} = useReward(false);
    const [data, setData] = useState<IRewardGrid[]>([]);
    const [details, setDetails] = useStickyState<IRewardDetail>(REWARD_DETAIL, "REWARD_GRID_DETAILS");


    function formatDate(date: string): string {
        switch (INTERVAL[setting.interval].label) {
            case "day":
            case "week":
                return date;
            case "month":
                return date.substring(0, 7);
            case "year":
                return date.substring(0, 4);
        }
        return "";
    }


    useEffect(() => {
        if (!rewards)
            return;

        let rewards_basic: IRewardGrid[] = [];

        rewards.forEach((rwd) => {
            let entry = rewards_basic.find((r) => r.date === rwd.date
                && r.category === (details.type ? rwd.category : '-')
                && r.pool === (details.pool ? rwd.pool : '-')
                && r.token === (details.token ? rwd.token : '-')
            );
            if (entry === undefined) {
                entry = {
                    date: rwd.date,
                    category: details.type ? rwd.category : '-',
                    pool: details.pool ? rwd.pool : '-',
                    token: details.token ? rwd.token : '-',
                    qty: 0,
                    value_bridge:0,
                    value: 0,
                    rate: 0,
                }
                rewards_basic.push(entry);
            }
            entry.value += rwd.value;
            entry.value_bridge += rwd.value_bridge;
            if (details.token) {
                entry.qty += rwd.qty;
                entry.rate = entry.value / entry.qty;
            }
        })

        let index = 0;
        rewards_basic.forEach((rwd) => rwd.id = index++);
        rewards_basic.forEach((rwd) => rwd.date = formatDate(rwd.date));
        setData(rewards_basic);
    }, [rewards, setting, details])

    return {rewardState, data, setting, details, setDetails, setSetting};
}