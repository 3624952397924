import React, {createContext, useContext, useEffect, useState} from "react";
import {IConfig} from "../global/Interface";
import {SERVER} from "../global/Consts";
import {useGlobalContext} from "./Global";
import {LOADING_SOURCE} from "../global/Enum";
import {useStickyState} from "../global/Hooks";


interface IContextProps {
    ak?: string,
    config?: IConfig,
    updateConfig: (config?: IConfig) => Promise<void>,
    updateAK: (ak?: string) => Promise<void>,
}

const ConfigContext = createContext<IContextProps>({
    ak: undefined,
    config: undefined,
    updateConfig: () => Promise.resolve(),
    updateAK: () => Promise.resolve(),
});


export const useConfigContext = () => {
    return useContext(ConfigContext);
}


export function ConfigContextProvider({children}: { children: any }): JSX.Element {
    const [ak, setAK] = useStickyState<string | undefined>(undefined, 'AK');
    const [config, setConfig] = useState<IConfig | undefined>(useConfigContext().config);
    const {setError, setLoading} = useGlobalContext();

    useEffect(() => {
        if (!ak)
            initConfig();
    },[]);


    useEffect(() => {
        if (!ak)
            return;

        let url = `${SERVER}/cfg/get/${ak}`;
        console.log(url);
        fetch(url)
            .then(result => result.json())
            .then(value => {
                setConfig(value);
                console.log(value);
            })
            .catch(() => setError());
    }, [ak]);


    const initConfig = () => {
        console.log("INIT CONFIG....");
        let url = `${SERVER}/cfg/init`;
        setLoading(true, LOADING_SOURCE.SAVE_CONFIG)
        fetch(url)
            .then(result => result.text())
            .then((value) => setAK(value))
            .catch(() => setError())
            .finally(() => setLoading(false, LOADING_SOURCE.SAVE_CONFIG));
    };

    const updateConfig = async (config?: IConfig) => {
        console.log("UPDATE CONFIG....", config);
        let url = `${SERVER}/cfg/set/${ak}`;

        setLoading(true, LOADING_SOURCE.SAVE_CONFIG)
        await fetch(url, {
            method: 'POST',
//              headers: {  'Content-Type': 'application/json' },
            body: JSON.stringify(config)
        })
            .then(() => setConfig(config))
            .catch((error) => {
                setError();
                console.log("Error: ", error)
            })
            .finally(() => setLoading(false, LOADING_SOURCE.SAVE_CONFIG));
    };


    const updateAK = async (ak_new?: string) => {
        let url = `${SERVER}/cfg/get/${ak_new}`;
        console.log("LOAD CONFIG", ak_new, url);
        await fetch(url)
            .then(response => response.ok ? setAK(ak_new) : Promise.reject("AK not found"))
            .catch(() => Promise.reject("AK not found"));

    }

    return (
        <ConfigContext.Provider value={{ak, config, updateConfig, updateAK}}>
            {children}
        </ConfigContext.Provider>
    );
}