import { LicenseInfo } from '@mui/x-license-pro';
import React from 'react';
import {GlobalContextProvider} from "./context/Global";
import {ConfigContextProvider} from "./context/Config";
import Main from "./Main";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useStickyState} from "./global/Hooks";

LicenseInfo.setLicenseKey(
    process.env.REACT_APP_MUI_LICENSE_KEY!
);


export default function App() {

    const [disclaimer, setDisclaimer] = useStickyState<boolean>(true, 'DISCLAIMER_V0.2.2');
    //const [disclaimer, setDisclaimer] = useState<boolean>(true);

    return (
        <GlobalContextProvider>
            <ConfigContextProvider>
                <Dialog
                    open={disclaimer}
                    fullWidth={true}
                    maxWidth={"xl"}
                >
                    <DialogTitle>Disclaimer & Cookies</DialogTitle>
                    <DialogContent dividers>
                        <DialogContent style={{border: "10px solid red", fontSize: "2em"}}>
                            <p>
                                DFI.tax and all related services are <strong>Beta Software</strong>!
                                Due to our best effort, we can and will not guarantee the
                                correctness, integrity or completeness of the data we provide.
                                <br/>
                                If you use this service, everything needs to be audited and checked
                                by yourself.
                            </p>
                            <p> CROPT GmbH and partners cannot be held liable
                                for any error, damage or unwanted
                                effect the use of the service may cause or have.
                            </p>
                            <p>
                                We uses cookies to personalise content
                                and provide best user experience. An on premises installation of
                                Matomo is uses to track user behaviour on our website.
                                To your best knowledge, this data is not shared with others.
                            </p>
                            <p>
                                All the data you provide (eg. addresses and settings) will
                                be stored on the backend server. We do not store any other
                                personal information.
                            </p>
                        </DialogContent>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant={"contained"}
                            color={"error"}
                            onClick={() => setDisclaimer(false)}>
                            I understand and accept
                        </Button>
                    </DialogActions>
                </Dialog>
                <Main/>
            </ConfigContextProvider>
        </GlobalContextProvider>
    );
}

