import React from 'react';
import {ResponsiveBar} from '@nivo/bar';
import { INTERVAL, MONTH,  YEAR} from "../global/Consts";
import {InfoOverlayShowToken, InfoOverlayType} from "../data/InfoOverlay";
import DropDown from "../elements/DropDown";
import AccType from "../elements/AccType";
import {useConfigContext} from "../context/Config";
import {useFormat} from "../global/Hooks";
import {useRewardChart} from "../da/RewardChart";


export default function RewardBarChart() {
    const {nf, nf_dyn} = useFormat();
    const {config} = useConfigContext();
    const {
        rewardState, data, setting, setSetting, tokens, pools
    } = useRewardChart();


    return (
        <div className="Box">
            <div className="BoxHeader Filter">
                <div className="FilterElement">
                    <DropDown
                        label="Show By"
                        current={setting.interval}
                        values={INTERVAL}
                        onChange={(item) => setSetting((set) => ({...set, interval: item}))}
                    />
                </div>
                <div className="FilterElement">
                    <DropDown
                        label="Year"
                        isDisabled={setting.year_disabled}
                        current={setting.year}
                        values={YEAR}
                        onChange={(item) => setSetting((set) => ({...set, year: item}))}
                    />
                </div>
                <div className="FilterElement">
                    <DropDown
                        label="Month"
                        isDisabled={setting.month_disabled}
                        current={setting.month}
                        values={MONTH}
                        onChange={(item) => setSetting((set) => ({...set, month: item}))}
                    />
                </div>
                <div className="FilterElement">
                    <AccType values={setting.types} onChange={(types) => setSetting((set) => ({...set, types}))}/>
                    <InfoOverlayType/>
                </div>
                <div className="FilterElement">
                    <DropDown
                        label="Tokens"
                        current={tokens.find((t) => t.value === setting.token)?.value || 0}
                        values={tokens}
                        onChange={(item) => setSetting((set) => ({...set, token: item}))}
                    />
                    <InfoOverlayShowToken cur_code={config?.cur_code}/>
                </div>
            </div>
            <div className="Diagram">
                <ResponsiveBar
                    borderRadius={5}
                    data={data}
                    keys={["Coinbase", ...pools]}
                    indexBy="date"
                    margin={{top: 20, right: 20, bottom: 35, left: 70}}
                    padding={0.4}
                    valueScale={{type: "linear"}}
                    colors={{scheme: 'accent'}}
                    // colors={["#ff00af99", "#007cff77","#f05f2fad"]}
                    //colors={["#a0a0a0", ...ASSETS.filter((ass) => ass.cat === ASSET_CAT.LP).map((ass) => ass.color)]}
                    animate={true}
                    enableLabel={false}
                    valueFormat={(x) => nf_dyn(x)}
                    axisTop={null}
                    axisRight={null}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: setting.token === -1 ? config?.cur_code : tokens.find((t) => t.value === setting.token)?.label || "",
                        legendPosition: "middle",
                        legendOffset: -50
                    }}
                />
            </div>
            <div className="BoxFooter">
                {rewardState?.complete ? "" : "STILL LOADING ==>"}
                Start: {nf(rewardState?.min_blk_id, 0)}
                &#160;--&#160;
                {rewardState?.min_blk_dt.split("T")[1]}
                &#160;--&#160;
                {rewardState?.min_blk_dt.split("T")[0]}
                &#160;=====&#160;
                End: {nf(rewardState?.max_blk_id, 0)}
                &#160;--&#160;
                {rewardState?.max_blk_dt.split("T")[1]}
                &#160;--&#160;
                {rewardState?.max_blk_dt.split("T")[0]}
            </div>
        </div>
    )
        ;
}

