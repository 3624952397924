import React from 'react';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as GiIcons from 'react-icons/gi';
import * as BsIcons from 'react-icons/bs';
import * as FaIcons from 'react-icons/fa';


export const SidebarData = [
    {
        title: 'Home',
        path: '/',
        icon: <AiIcons.AiFillHome />,
        cName: 'nav-text'
    },
    {
        title: 'Reward',
        path: '/reward',
        icon: <GiIcons.GiReceiveMoney />,
        cName: 'nav-text'
    },
    {
        title: 'Diagram',
        path: '/diagram',
        icon: <BsIcons.BsBarChart/>,
        cName: 'nav-text'
    },
    {
        title: 'History',
        path: '/history',
        icon: <BsIcons.BsCalendar/>,
        cName: 'nav-text'
    },
    // {
    //     title: 'Export *beta*',
    //     path: '/export',
    //     icon: <BiIcons.BiExport/>,
    //     cName: 'nav-text'
    // },
    {
        title: 'Changelog',
        path: '/changelog',
        icon: <IoIcons.IoIosPaper />,
        cName: 'nav-text'
    },
    {
        title: 'FAQ',
        path: '/faq',
        icon: <FaIcons.FaQuestion />,
        cName: 'nav-text'
    },
    {
        title: 'Donate',
        path: '/donate',
        icon: <AiIcons.AiFillHeart />,
        cName: 'nav-text'
    },
    {
        title: 'About',
        path: '/about',
        icon: <IoIcons.IoMdHelpCircle />,
        cName: 'nav-text'
    }
];
