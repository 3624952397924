import React from 'react';

export default function About() {

    return (
        <div>
            <div className="Box">
                <div className="BoxHeader Headline">
                    About DFI.tax
                </div>
                <div className="BoxContent TEXT_2">
                    <div className="HEADING_1">Welcome,</div>
                    <div className="BLOCK_1">
                        on this website. DFI.tax analyses your rewards from Liquidity Mining and Staking
                        for multiple DeFiChain addresses.<br/>
                        We calculate the value in USD currency at the time the reward was distributed to the address(es)
                        on an daily base. The rewards can be displayed in different other currencies.<br/>
                        You can display the data aggregated by day, week, month or year.
                        <p>
                            This is just the beginning, <a href="changelog">more</a> to come.
                            You can follow us on
                            &#160;<a href="https://www.twitter.com/dfi_tax" target="_blank" rel="noreferrer">twitter</a>
                            &#160;or&#160;
                            <a href="https://t.me/dfi_tax" target="_blank" rel="noreferrer">telegram</a>
                            &#160;to get the latest updates.
                        </p>
                        <p>
                            The website is in an very early state and will be improved
                            and updated regularly. We use different methods to obtain the data,
                            including unofficial and modified node software.<br/>
                            Even we are very careful, we cannot assure that
                            the data is correct or complete. Actually we need YOUR help to review
                            the data and let us know if there is any discrepancy.<br/>
                            If you find any bugs, typos or have feature requests don't hesitate to
                            &#160;<a href="mailto:office@dfi.tax">let us know</a>.
                        </p>
                    </div>
                    <div className="HEADING_1">Developer,</div>
                    <div className="BLOCK_1">
                        My name is Martin and I consider myself a developer since the age of 10
                        when I got my first PC and started programming with Quick Basic.<br/>
                        Even though I am a developer for over 30 years,
                        DFI.tax is my first public project I build from scratch on my own.
                    </div>
                    <div className="HEADING_1">Impressum</div>
                    <div className="BLOCK_1">
                        <strong>CROPT GmbH</strong><br/>
                        CEO: DI Martin Schmeisser, BSc<br/>
                        Ursprungweg 42b<br/>
                        8045 Graz<br/>
                        Austria<br/>

                        Email: <a href="mailto:office@dfi.tax">office@dfi.tax</a><br/>
                        Telegram personal: <a href="https://t.me/dfi_to" target="_blank"
                                              rel="noreferrer">https://t.me/dfi_to</a><br/>
                        Telegram channel: <a href="https://t.me/dfi_tax" target="_blank"
                                             rel="noreferrer">https://t.me/dfi_tax</a>

                    </div>
                </div>
                <div className="BoxFooter"/>
            </div>
        </div>
    );
}
