import React, {createContext, useContext, useState} from "react";
import {ERROR_TYPE, LOADING_SOURCE} from "../global/Enum";

interface IContextProps {
    isLoading: (source?: LOADING_SOURCE) => boolean,
    showError: boolean,
    setLoading: (loading: boolean, source: LOADING_SOURCE) => void,
    setError: (type?: ERROR_TYPE) => void,
    clearError: () => void,  // clearError: Function,
}

const GlobalContext = createContext<IContextProps>({
    isLoading: () => false,
    showError: false,
    setLoading: () => null,
    setError: () => null,
    clearError: () => null
});


export const useGlobalContext = () => {
    return useContext(GlobalContext);
}

export function GlobalContextProvider({children}: { children: any }): JSX.Element {
    const [loading_sources, setLoadingSources] = useState<LOADING_SOURCE[]>([]);
    const [showError, setShowError] = useState(false);

    const setLoading = (loading: boolean, source: LOADING_SOURCE) => {
        loading ?
            setLoadingSources(ls => ls.concat(source)) :
            setLoadingSources(ls => ls.filter((src) => src !== source));
    };

    const isLoading = (source?: LOADING_SOURCE): boolean => {
        if (source === undefined)
            return loading_sources.length > 0;
        return loading_sources.indexOf(source) > -1;
    }

    const setError = (type?: ERROR_TYPE) => {
        setShowError(true);
        setTimeout(() => {
            setShowError(false);
        }, 10000);
    };

    const clearError = () => {
        setShowError(false);
    }

    return (
        <GlobalContext.Provider value={{isLoading, showError, setLoading, setError, clearError}}>
            {children}
        </GlobalContext.Provider>
    );
}