import {useEffect, useState} from "react";
import {INTERVAL} from "../global/Consts";
import {weekNumber} from "weeknumber";
import {ISetting, useReward} from "./Reward";
import {IDropDown} from "../global/Interface";


interface IData {
    [index: string]: string | number
}

interface IChartSetting extends ISetting {
    token: number

}


export function useRewardChart() {
    const {setting: rewardSetting, rewards, rewardState, setSetting: setRewardSetting} = useReward();
    const [data, setData] = useState<IData[]>([]);
    const [tokens, setTokens] = useState<IDropDown[]>([]);
    const [pools, setPools] = useState<string[]>([]);
    const [setting, setSetting] = useState<IChartSetting>({token: -1, ...rewardSetting});

    function formatDate(date: string): string {
        switch (INTERVAL[setting.interval].label) {
            case "day":
                return date.substring(8, 10);
            case "week":
                return weekNumber(new Date(date)).toString();
            case "month":
                return date.substring(0, 7);
            case "year":
                return date.substring(0, 4);
        }
        return "";
    }

    useEffect(() => {
        setRewardSetting(setting);
    }, [setting]);


    useEffect(() => {
            if (!rewards)
                return;

            // setting all tokens
            let index = -1;
            let tokens = rewards.reduce((tt: IDropDown[], r) => {
                if (!tt.find((t) => t.label === r.token))
                    tt.push({value: index++, label: r.token});
                return tt;
            }, [{value: index++, label: '-ALL-'}, {value: index++, label: 'DFI'}]);
            setTokens(tokens);

            // setting all pools
            let pools = rewards.reduce((tt: string[], r) => {
                if (!tt.find((t) => t === r.pool))
                    tt.push(r.pool!);
                return tt;
            }, []);
            setPools(pools);

            let dates = rewards.reduce((set, current) =>
                set.add(current.date), new Set());

            let data: IData[] = (Array.from(dates) as string[]).map(date => ({date}));
            data.reverse();

            if (setting.token === -1)
                data.forEach((entry) => {
                    rewards.filter((x) => x.date === entry.date).forEach((y) => {
                        entry[y.pool ?? y.category] = +(entry[y.pool ?? y.category] as number ?? 0) + y.value;
                    })
                });
            else
                data.forEach((entry) => {
                    rewards.filter((x) => x.date === entry.date
                        && x.token === tokens.find((t) => t.value === setting.token)!.label).forEach((y) => {
                        entry[y.pool ?? y.category] = (entry[y.pool ?? y.category] as number ?? 0) + (y.qty ?? 0);
                    })
                });

            // set correct date length for year, month, week
            data.forEach((entry) => entry["date"] = formatDate(entry["date"] as string));
            setData(data);
        }
        ,
        [rewards, setting]
    )

    return {rewardState, data, setting, tokens, pools, setSetting};
}