import {Entry} from "./ChangelogData";


export const FeaturesData: Entry[] = [
    {
        header: "2024",
        content: [
            "to be announced",
        ],
    }
    /*
    {
        header: "v0.3.x - Feb-March 2022",
        content: [
            "Use prices from DEX instead of CEX",
            "TaxEx: Export Engine with priority for CoinTracking",
            "Tax Optimiser light"
        ],
    },
    {
        header: "v0.4.x - April-May 2022",
        content: [
            "\"Your wish is my command\"",
            "Further export options and services",
            "Refactoring, Optimisations, CleanUp",
            "Dark Theme",
            "Improve for mobile devices",
            "Multilingual support",
        ]
    },
    {
        header: "v0.5.x - Jun-Aug 2022",
        content: [
            "Backbone 2.0",
            "TimeMachine",
        ]
    },
    {
        header: "v0.6.x - Sept 2022",
        content: [
            "Cake integration",
            "Further tax features",
            "Extended PDF Report",
        ]
    },
    {
        header: "v0.7.x - Oct-Dec 2022",
        content: [
            "Get DFI.tax certified",
            "Collaborate with tax consultants",
        ]
    },
     */
]

