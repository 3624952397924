import React from 'react';
import {
    DataGridPro, DataGridProProps,
    GridColDef, GridColumnVisibilityModel,
    GridToolbar,
} from "@mui/x-data-grid-pro";
import DropDown from "../elements/DropDown";
import AccType from "../elements/AccType";
import {useConfigContext} from "../context/Config";
import {useFormat} from "../global/Hooks";
import {IHistoryIntern, useHistory, YEAR} from "../da/History";
import {MONTH} from "../global/Consts";
import LinkIcon from '@mui/icons-material/Link';
import {FormControlLabel, IconButton, Switch, Tooltip} from "@mui/material";
import {InfoOverlayHistoryIntern} from "../data/InfoOverlay";


export default function History() {
    const {nf_dyn} = useFormat();
    const {config} = useConfigContext();

    const {
        dataNew,
        setting,
        setSetting
    } = useHistory();

    const COLUMN_VISIBLE_MODEL: GridColumnVisibilityModel = {
        adr: false,
        blk_id: false,
        cat: false,
    };

    const COLUMN_DEF: GridColDef[] = [
        {
            headerName: "Block",
            field: "blk_id",
        },
        {
            headerName: "Address",
            field: "adr",
        },
        {
            headerName: "TX",
            field: "tx_id",
            width: 60,
            renderCell: (data) =>
                (data.row.tx_id ?
                    <>
                        <IconButton href={`https://defiscan.live/transactions/${data.value}`} target="_blank">
                            <Tooltip title="DefiScan.live">
                                <LinkIcon
                                    fontSize={"medium"}/>
                            </Tooltip>
                        </IconButton>
                    </> : <></>)
        },
        {
            headerName: "Category",
            field: "cat",
            width: 150,
        },
        {
            headerName: "Quantity",
            field: "qty",
            width: 100,
            align: "right",
            headerAlign: "right",
            valueFormatter: (data) => nf_dyn(data.value),
            cellClassName: (params) =>
                ((params.value > 0) ? "DEBIT" : "CREDIT")
        },
        {
            headerName: "Code",
            field: "code",
            width: 100,
            align: "right",
            headerAlign: "right",
            renderCell: (data) =>
                data.row.utxo ? "*" + data.value + "*" : data.value,
            cellClassName: (params) =>
                ((params.row.qty > 0) ? "DEBIT" : "CREDIT"),
        },
        {
            headerName: `Value ${config?.pfe.bridge_token}`,
            field: "value_bridge",
            align: "right",
            headerAlign: "right",
            width: 120,
            valueFormatter: (data) => {
                if (!data.value || data.value === 0)
                    return;
                return `${nf_dyn(data.value)} ${config?.pfe.bridge_token}`
            },
            cellClassName: (params) =>
                ((params.row.qty > 0) ? "DEBIT" : "CREDIT"),
        },
        {
            headerName: `Value ${config?.cur_code}`,
            field: "value",
            width: 120,
            align: "right",
            headerAlign: "right",
            valueFormatter: (data) =>
                `${nf_dyn(data.value)} ${config?.cur_code}`,
            cellClassName: (params) =>
                (params.row.tx_id ? "" : (params.row.qty > 0) ? "DEBIT" : "CREDIT"),
        },
        {
            headerName: "Fee DFI",
            align: "right",
            headerAlign: "right",
            field: "fee_qty",
            width: 100,
        },
        {
            headerName: `Fee ${config?.cur_code}`,
            field: "fee_value",
            width: 150,
            align: "right",
            headerAlign: "right",
            valueFormatter: (data) => {
                if (!data.value || data.value === 0)
                    return;
                return nf_dyn(data.value) + " " + config?.cur_code
            },
        },
    ];


    const GROUPING_COLUMN_DEF: DataGridProProps['groupingColDef'] = {
        headerName: 'Date',
        width: 400,
        cellClassName: 'Date',
        hideDescendantCount: true,
        valueGetter: (params) => {
            let row: IHistoryIntern = params.row;
            return row.adr ?? `${row.dt?.replace("T", " ")} - << ${row.cat} >>`;
        },
    };

    return (
        <div className="Box">
            <div className="BoxHeader Filter">
                <div className="FilterElement">
                    <DropDown
                        label="Year"
                        current={setting.year}
                        values={YEAR}
                        onChange={(item) => setSetting((set) => ({...set, year: item}))}
                    />
                </div>
                <div className="FilterElement">
                    <DropDown
                        label="Month"
                        current={setting.month}
                        values={MONTH}
                        onChange={(item) => setSetting((set) => ({...set, month: item}))}
                    />
                </div>

                <div className="FilterElement">
                    <AccType width={300}
                             values={setting.types}
                             onChange={(types) => setSetting((set) => ({...set, types}))}
                    />
                </div>
                <div className="FilterElement">
                    <FormControlLabel
                        labelPlacement="top"
                        control={<Switch
                            checked={setting.intern}
                            onChange={() => setSetting((set) => ({...set, intern: !setting.intern}))}/>
                        } label={<div>Intern<InfoOverlayHistoryIntern/></div>}
                    />
                </div>
            </div>
            <div>
                <DataGridPro
                    columnVisibilityModel={COLUMN_VISIBLE_MODEL}
                    columns={COLUMN_DEF}
                    rows={dataNew}
                    getRowId={(row) => row.id}
                    autoHeight={true}
                    hideFooter={true}
                    rowHeight={35}
                    headerHeight={45}
                    getRowClassName={(params) => {
                        let css_class = params.row.id_tx % 2 === 0 ? 'ROW-EVEN' : 'ROW-ODD';
                        if (params.row.cat)
                            css_class += " STRONG";
                        if (params.row.intern)
                            css_class += " INTERN";
                        if (params.row.utxo)
                            css_class += " UTXO";
                        return css_class;
                    }
                    }
                    components={{Toolbar: GridToolbar}}
                    treeData={true}
                    groupingColDef={GROUPING_COLUMN_DEF}
                    getTreeDataPath={(row) => row.path}
                    defaultGroupingExpansionDepth={1}
                />
            </div>
            <div className="BoxFooter"/>
        </div>
    )
        ;
}

