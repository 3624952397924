import React from 'react';
import Reward from '../components/Reward'
import Address from '../components/Address'


export default function AccountReward() {


    return (
        <div>
            <Address />
            <Reward />
        </div>
    );
}
