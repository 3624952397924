import React, {useEffect, useState} from 'react';
import {
    DataGridPro,
    GridColDef, GridColumnVisibilityModel,
    GridRenderCellParams,
    GridToolbar,
    GridValueFormatterParams
} from "@mui/x-data-grid-pro";
import {INTERVAL, MONTH, YEAR} from "../global/Consts";
import {InfoOverlayRewardListDetail, InfoOverlayType} from "../data/InfoOverlay";
import {FormControlLabel, Switch} from "@mui/material";
import AccType from "../elements/AccType"
import DropDown from "../elements/DropDown";
import {useConfigContext} from "../context/Config";
import {useFormat} from "../global/Hooks";
import {useRewardGrid} from "../da/RewardGrid";


export default function Reward() {
    const {nf, nf_dyn} = useFormat();
    const {config} = useConfigContext();
    const {
        details,
        rewardState,
        data,
        setting,
        setSetting,
        setDetails
    } = useRewardGrid();

    const [vis_state, setVisState] =
        useState<GridColumnVisibilityModel>({
            category: false,
            pool: false,
            token: false,
            qty: false,
            rate: false
        });

    useEffect(() => {
        setVisState({
            category: details.type,
            pool: details.pool,
            token: details.token,
            qty: details.token,
            rate:details.token,
        })
    }, [details]);

    const valueFormatter = (data: GridValueFormatterParams) => {
        if (data.value === undefined)
            return;
        return data.value + " " + config?.cur_code;
    };

    const renderCell = (data: GridRenderCellParams, code: string) => {
        if (data.value === undefined)
            return;
        return (<>{nf_dyn(data.value)}&#160;<strong>{code}</strong></>);
    };

    const VALUE_BRIDGE = `Value ${config?.pfe.bridge_token}`;
    const VALUE = `Value ${config?.cur_code}`;
    const RATE = `Rate ${config?.cur_code}`;

    const COLUMN_DEF: GridColDef[] = [
        {
            headerName: "Date",
            field: "date",
        },
        {
            headerName: "Type",
            field: "category",
            width: 120,
        },
        {
            headerName: "Pool",
            field: "pool",
        },
        {
            headerName: "Token",
            field: "token",
        },
        {
            headerName: "Quantity",
            field: "qty",
            renderCell: (data) => nf_dyn(data.value as number),
            align: "right",
            headerAlign: "right",
            // FIXME: use something more generic for this CSS class
            cellClassName: 'BALANCE',
        },
        {
            headerName: VALUE_BRIDGE,
            field: "value_bridge",
            align: "right",
            headerAlign: "right",
            width: 140,
            renderCell: (data) => renderCell(data, config!.pfe.bridge_token),
        },
        {
            headerName: VALUE,
            field: "value",
            minWidth: 140,
            align: "right",
            headerAlign: "right",
            cellClassName: 'BALANCE',
            valueFormatter: valueFormatter,
            renderCell: (data) => renderCell(data, config!.cur_code),
        },
        {
            headerName: RATE,
            field: "rate",
            width: 140,
            valueFormatter: valueFormatter,
            renderCell: (data) => renderCell(data, config!.cur_code),
            align: "right",
            headerAlign: "right",
            cellClassName: 'BALANCE',
        },
    ];

    return (
        <div className="Box">
            <div className="BoxHeader Filter">
                <div className="FilterElement">
                    <DropDown
                        label="Show By"
                        current={setting.interval}
                        values={INTERVAL}
                        onChange={(item) => setSetting((set) => ({...set, interval: item}))}
                    />
                </div>
                <div className="FilterElement">
                    <DropDown
                        label="Year"
                        isDisabled={setting.year_disabled}
                        current={setting.year}
                        values={YEAR}
                        onChange={(item) => setSetting((set) => ({...set, year: item}))}
                    />
                </div>
                <div className="FilterElement">
                    <DropDown
                        label="Month"
                        isDisabled={setting.month_disabled}
                        current={setting.month}
                        values={MONTH}
                        onChange={(item) => setSetting((set) => ({...set, month: item}))}
                    />
                </div>
                <div className="FilterElement">
                    <AccType values={setting.types} onChange={(types) => setSetting((set) => ({...set, types}))}/>
                    <InfoOverlayType/>
                </div>
                <div className="FilterElement">
                    <FormControlLabel
                        labelPlacement="top"
                        control={<Switch
                            checked={details.type}
                            onChange={() => setDetails((det) => ({...det, type: !details.type}))}/>
                        } label={<div>Types<InfoOverlayRewardListDetail/></div>}
                    />
                </div>
                <div className="FilterElement">
                    <FormControlLabel
                        labelPlacement="top"
                        control={<Switch
                            checked={details.pool}
                            onChange={() => setDetails((det) => ({...det, pool: !details.pool}))}/>
                        } label={<div>Pool<InfoOverlayRewardListDetail/></div>}
                    />
                </div>
                <div className="FilterElement">
                    <FormControlLabel
                        labelPlacement="top"
                        control={<Switch
                            checked={details.token}
                            onChange={() => setDetails((det) => ({...det, token: !details.token}))}/>
                        } label={<div>Token<InfoOverlayRewardListDetail/></div>}
                    />
                </div>
            </div>

            <div id="myGrid">
                <DataGridPro
                    columnVisibilityModel={vis_state}
                    columns={COLUMN_DEF}
                    rows={data}
                    autoHeight={true}
                    hideFooter={true}
                    rowHeight={35}
                    headerHeight={45}
                    components={{Toolbar: GridToolbar}}
                />
            </div>

            <div className="BoxFooter">
                {rewardState?.complete ? "" : "STILL LOADING ==>"}
                Start: {nf(rewardState?.min_blk_id, 0)}
                &#160;--&#160;
                {rewardState?.min_blk_dt.split("T")[1]}
                &#160;--&#160;
                {rewardState?.min_blk_dt.split("T")[0]}
                &#160;=====&#160;
                End: {nf(rewardState?.max_blk_id, 0)}
                &#160;--&#160;
                {rewardState?.max_blk_dt.split("T")[1]}
                &#160;--&#160;
                {rewardState?.max_blk_dt.split("T")[0]}
            </div>
        </div>
    )
        ;
}

