import {IDropDown, ITypeOptions} from "./Interface";


export const SERVER = process.env.REACT_APP_REST_SERVER!;

export const range = (start: number, stop: number) =>
    Array.from({length: (stop - start + 1)}, (_, i) => start + (i));

export const YEARS = () => range(2020, new Date().getUTCFullYear()).reverse();

export const INTERVAL: IDropDown[] = [
    {value: 0, label: 'day'},
    {value: 1, label: 'week'},
    {value: 2, label: 'month'},
    {value: 3, label: 'year'},
];


export const YEAR = [
    {value: 0, label: '-ALL-'},
    ...YEARS().map((y) =>
        ({value: y, label: y.toString()}))
]

export const MONTH = [
    {value: 0, label: '-ALL-'},
    ...range(1,12).reverse().map((m) =>
        ({value: m, label: m.toString()})),
]


export const TYPE_OPTIONS: ITypeOptions = {
    "Coinbase": {label: "Coinbase", active: true, enabled: true},
    "Commission": {label: "Commission", active: true, enabled: true},
    "Rewards": {label: "Rewards", active: true, enabled: true},
};

export const CUR_CODES = [
        {label: 'USD', value: 'USD'},
        {label: 'EUR', value: 'EUR'},
        {label: 'CHF', value: 'CHF'},
        {label: 'GBP', value: 'GBP'},
        {label: 'RUB', value: 'RUB'},
        {label: 'JPY', value: 'JPY'},
        {label: 'CNH', value: 'CNH'},
        {label: 'SEK', value: 'SEK'},
        {label: 'AUD', value: 'AUD'},
        {label: 'CAD', value: 'CAD'},
        {label: 'SGD', value: 'SGD'},
        {label: 'BTC', value: 'BTC'},
        {label: 'ETH', value: 'ETH'},
        {label: 'DFI', value: 'DFI'},
    ]
;

export const LOCALES = [
    {label: 'en-us', value: 'en-us'},
    {label: 'de-de', value: 'de-de'}
]

export const BRIDGE_TOKEN = [
        {label: 'DFI', value: 'DFI'},
        {label: 'BTC', value: 'BTC'},
        {label: 'ETH', value: 'ETH'},
        {label: 'USDC', value: 'USDC'},
        {label: 'USDT', value: 'USDT'},
        {label: 'LTC', value: 'LTC'},
        {label: 'BCH', value: 'BCH'},
        {label: 'DOGE', value: 'DOGE'},
    ]
;

export const EXCHANGE = [
        {label: 'BITTREX', value: 'BITTREX'},
        {label: 'KUCOIN', value: 'KUCOIN'},
    ]
;

export const PRICE_FRAME = [
        {label: 'OPEN', value: 'OPEN'},
        {label: 'CLOSE', value: 'CLOSE'},
    ]
;


export const HOUR_REWARD = [
    ...range(0,23).map((m) =>
        ({value: m, label: m.toString()})),
    ]
;

