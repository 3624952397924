import React, {Dispatch, SetStateAction, useEffect} from "react";
import {useConfigContext} from "../context/Config";
import {LOCALE} from "../components/Config";


export function useStickyState<T>(defaultValue: any, key: string): [T, Dispatch<SetStateAction<T>>] {

    const [value, setValue] = React.useState<T>(() => {
        const stickyValue = window.localStorage.getItem(key);

        let result: T = (stickyValue !== null)
            ? JSON.parse(stickyValue)
            : defaultValue;

        return result;
    });

    React.useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
}

export function useDebug(value: any, name?: String) {

    useEffect(() => {
        if (name)
            console.log(name, value);
        else
            console.log(value);
    }, [value])
}

export function useFormat() {
    const {config} = useConfigContext();

    const nf = (num: string | number | undefined, pre?: number, cur?: String): string => {
        if (num === undefined)
            return "";
        let number = parseFloat(String(num)).toLocaleString(LOCALE, {
            minimumFractionDigits: pre,
            maximumFractionDigits: pre
        })
        if (cur !== undefined)
            number = number + " " + cur;
        return number;
    }


    const  nf_dyn = (num: number | undefined, cur?: String): string => {
        if (num === undefined)
            return "";
        let pre = 2;
        if (Math.abs(num) < 1 && Math.abs(num) > 0) {
            pre = Math.min(Math.log10(1 / Math.abs(num)) + 3, 12);
        }

        let number = parseFloat(String(num)).toLocaleString(config?.locale, {
            minimumFractionDigits: pre,
            maximumFractionDigits: pre
        })
        if (cur !== undefined)
            number = number + " " + cur;
        return number;
    }

    return { nf, nf_dyn };
}