import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {Checkbox, ListItemText} from "@mui/material";
import {ITypeOptions} from "../global/Interface";
import {useState} from "react";

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface IAccTypeProps {
    values: ITypeOptions,
    onChange: ((types: ITypeOptions) => void)
    width?: number
}


export default function AccType(props: IAccTypeProps) {
    const [values, setValues] = useState(props.values);

    const handleChange = (event: any) => {
        let x = event.target.value as string[];
        Object.keys(values).forEach((key) => {
            values[key].active = x.some(y => y === key);
        });
        setValues({...values});
    }

    // send onChange event only after dropdown is closed and all
    // selections have been done
    const handleClose = (event: any) => {
        props.onChange({...values});
    }

    return (
        <span>
            <FormControl sx={{m: 1, width: props.width ?? 300}}>
                <InputLabel id="demo-multiple-checkbox-label">Type</InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={Object.keys(values).filter(x => values[x].active && values[x].enabled)}
                    onChange={handleChange}
                    onClose={handleClose}
                    input={<OutlinedInput label="Type"/>}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {Object.keys(values).map((key) => (
                        <MenuItem key={values[key].label} value={values[key].label} disabled={!values[key].enabled}>
                            <Checkbox checked={values[key].active}/>
                            <ListItemText primary={values[key].label}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </span>
    );
}