import {Alert, AlertTitle, Collapse, IconButton, InputAdornment, TextField, Tooltip} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React, {useState} from "react";
import {IAddress} from "../global/Interface";

const MAX_NUMBER_OF_ADDRESSES = 100;


interface IAddAdrProps {
    addresses: IAddress[],
    onChange: ((addresses: IAddress[]) => void)
}


export default function AddAddress(props: IAddAdrProps) {
    const [showInvalidAlert, setShowInvalidAlert] = useState(false);
    const [adr_tmp, setAdrTmp] = useState("");
    const [adr_prior, setAdrPrior] = useState("");


    async function addAddress(adr: string) {
        adr = adr.trim();
        setShowInvalidAlert(false);

        if (adr.length === 0
            || props.addresses.some((entry: IAddress) => entry.value === adr)
            || props.addresses.length >= MAX_NUMBER_OF_ADDRESSES) {
            setAdrTmp("");
            return;
        }

        setAdrPrior(adr);
        if (adr.match(/^[a-z0-9]{34,42}$/i))
            props.onChange(props.addresses.concat({value: adr, enabled: true}));
        else
            setShowInvalidAlert(true);
        setAdrTmp("");
    }

    const addAddressEvent = async () => {
        await addAddress(adr_tmp);
    }

    return (
        <div className="Setting AddAddressS">
            <TextField className="Setting AdrField"
                       id="standard-name"
                       label="DefiChain Address"
                       value={(props.addresses.length < MAX_NUMBER_OF_ADDRESSES) ? adr_tmp :
                           ("You can only add up to " + MAX_NUMBER_OF_ADDRESSES + " addresses")}
                       onChange={(value) => setAdrTmp(value.currentTarget.value)}
                       onKeyUp={(value: any) => {
                           if (value.keyCode === 13)
                               addAddressEvent().then();
                       }}
                       disabled={!(props.addresses.length < MAX_NUMBER_OF_ADDRESSES)}

                       InputProps={{
                           endAdornment: (
                               <InputAdornment position={"end"}>
                                   <IconButton
                                       onClick={addAddressEvent}
                                   >
                                       <Tooltip title="Add address">
                                           <AddCircleIcon
                                               color={"primary"}
                                               fontSize={"large"}/>
                                       </Tooltip>
                                   </IconButton>
                               </InputAdornment>
                           )
                       }}
            />

            <Collapse in={showInvalidAlert}>
                <Alert className="AdrAlert" severity="error" onClose={() => setShowInvalidAlert(false)}>
                    <AlertTitle>Address <strong>{adr_prior}</strong> seems invalid</AlertTitle>
                    <p>
                        Please check that it's a valid address.<br/>
                        If you think it's a valid address and still get this message, please let me know at
                        &#160;<a href="mailto:bug@dfi.tax">bug@dfi.tax</a>.
                    </p>
                </Alert>
            </Collapse>
        </div>
    )
}