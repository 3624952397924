import {useEffect, useState} from "react";
import {INTERVAL, MONTH, SERVER, TYPE_OPTIONS, YEAR} from "../global/Consts";
import {LOADING_SOURCE} from "../global/Enum";
import {useGlobalContext} from "../context/Global";
import {useConfigContext} from "../context/Config";
import {ITypeOptions} from "../global/Interface";
import {useStickyState} from "../global/Hooks";
import moment from "moment";


export interface ISetting {
    interval: number,
    types: ITypeOptions,
    month: number,
    year: number,
    month_disabled: boolean,
    year_disabled: boolean,
}

const DEFAULT_SETTING: ISetting = {
    interval: INTERVAL[0].value,
    types: TYPE_OPTIONS,
    month: MONTH[0].value,
    year: YEAR[0].value,
    month_disabled: false,
    year_disabled: false,
}


export interface IRewardState {
    min_blk_id: number,
    min_blk_dt: string,
    max_blk_id: number,
    max_blk_dt: string,
    complete: boolean,
}

export interface IRewardSheet extends IRewardState {
    rewards: IRewardRaw[]
}

interface IRewardRaw {
    date: string,
    category: string,
    pool?: string,
    token: string,
    qty: number,
    value_bridge: number,
    value: number,
}

export interface IReward {
    date: string,
    category: string,
    value_bridge: number,
    value: number,
    pool?: string,
    token: string,
    qty: number,
    rate: number,
}


export function useReward(limitData: boolean = true) {
    const {setLoading, isLoading, setError} = useGlobalContext();
    const {ak, config} = useConfigContext();
    const [rewardSheet, setRewardSheet] = useState<IRewardSheet>();
    const [rewardState, setRewardState] = useState<IRewardState>();
    const [rewards, setRewards] = useState<IReward[]>([]);
    const [setting, setSetting] = useStickyState<ISetting>(DEFAULT_SETTING, "SETTING_REWARD")

    function getRewardOut(reward: IRewardRaw): IReward {
        return {
            ...reward,
            rate: reward.value / reward.qty,
            value_bridge: reward.value_bridge,
            value: reward.value,
        }
    }

    function getLastDay(month: number, year: number): number {
        if (month === 2)
            if (year % 4 === 0)
                return 29;
            else
                return 28;

        if ([1, 3, 5, 7, 8, 10, 12, 0].includes(month))
            return 31;
        return 30;
    }

    useEffect(() => {
        if (!ak || isLoading(LOADING_SOURCE.LOAD_REWARD))
            return;

        let begin = `${setting.year === 0 ? 2000 : setting.year}-${setting.month === 0 ? 1 : setting.month}-01`;
        let end = `${setting.year === 0 ? 2100 : setting.year}-${setting.month === 0 ? 12 : setting.month}-${getLastDay(setting.month, setting.year)}`;
        const url = `${SERVER}/rwd/${ak}/${begin}/${end}/${INTERVAL[setting.interval].label}`;
        console.log(url);
        setLoading(true, LOADING_SOURCE.LOAD_REWARD);
        fetch(url)
            .then(result => result.json())
            .then(value => {
                setSetting((set) => {
                    Object.keys(set.types).forEach((type) => {
                        set.types[type].enabled = value.rewards.some((res: {
                            category: string
                        }) => res.category === type)
                    })
                    return {...set, types: set.types};
                });
                setRewardState(value);
                setRewardSheet(value);
            }).catch(() => setError())
            .finally(() => setLoading(false, LOADING_SOURCE.LOAD_REWARD))
    }, [ak, config, setting.interval, setting.year, setting.month]);


    useEffect(() => {
        if (!rewardSheet)
            return;

        if (setting.interval >= 2) {
            setting.month_disabled = true;
            setting.month = 0;
        } else {
            setting.month_disabled = false;
        }

        if (setting.month !== 0 && setting.year === 0)
            setting.year = new Date().getFullYear();

        // filter only active reward types
        let rewards = rewardSheet.rewards.filter((rwd) =>
            setting.types[rwd.category].active);

        setRewards(rewards.map((rwd: IRewardRaw) => getRewardOut(rwd)));
        setSetting(setting);
    }, [rewardSheet, setting, limitData])


    return {setting, rewards, rewardState, setSetting};
}