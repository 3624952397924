import React from "react";
import {styled, Tooltip, tooltipClasses, TooltipProps} from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(13),
        border: '1px solid #dadde9',
    },
}));


export function InfoOverlayType() {

    return (
        <HtmlTooltip placement="right-start" title={
            <div>
                <div style={{fontWeight: "bold", fontSize: "1.25em"}}>Select which type to display</div>
                <hr/>
                <p>
                    <strong>Coinbase:</strong>&#160;
                    This rewards result from staking and
                    are distributed to the miner of the block.
                    The reward for each minted block decreases every
                    32,690 blocks (~11 days) by 1,658%.
                </p>
                <p>
                    <strong>Reward:</strong>&#160;
                    This rewards are distributed proportionately on all the
                    liquidity providers in the same pool. They reward decreases at
                    the same rate as Coinbase/Staking.
                </p>
                <p>
                    <strong>Commission:</strong>&#160;
                    When somebody is using the pool to swap tokens,
                    a commission(0,2%) is distributed proportionately among
                    all liquidity providers (people who holds LP Tokens for that pool).
                </p>
            </div>
        }>
            <div className="InfoOverlay">
                <HelpOutlineIcon color={"primary"} fontSize={"small"}/>
            </div>
        </HtmlTooltip>
    )
}

export function InfoOverlayShowToken(props: { cur_code?: string }) {
    return (
        <HtmlTooltip placement="right-start" title={
            <div>
                <div style={{fontWeight: "bold", fontSize: "1.25em"}}>Select which tokens to display</div>
                <hr/>
                <p>
                    If you select <strong>--ALL--</strong> every distributed token
                    will be shown with their value accumulated in <strong>{props.cur_code}</strong>.
                </p>
                <p>
                    If you select a specific token, then only distributions for
                    this tokens will be shown.
                </p>
            </div>
        }>
            <div className="InfoOverlay">
                <HelpOutlineIcon color={"primary"} fontSize={"small"}/>
            </div>

        </HtmlTooltip>
    )
}


export function InfoOverlayLPDetail() {

    return (
        <HtmlTooltip placement="right-start" title={
            <div>
                <div style={{fontWeight: "bold", fontSize: "1.25em"}}>Display LP Tokens</div>
                <hr/>
                <p>
                    <strong>When enabled</strong>&#160;
                    all tokens are shown as they are on the address.<br/>
                    Also, UTXO und DFI token are shown as separate entries if possible.
                </p>
                <p>
                    <strong>When disabled</strong>&#160;
                    all LP tokens get resolved into their underlying tokens.<br/>
                    e.g. a BTC-DFI token is shown with it's currently containing DFI and BTC tokens.
                    This underlying tokens are accumulated with other underlying tokens from
                    other LP Tokens (e.g. ETH-DFI) and the same token that exist on that address.
                </p>
            </div>
        }>
            <div className="InfoOverlay">
                <HelpOutlineIcon color={"primary"} fontSize={"small"}/>
            </div>

        </HtmlTooltip>
    )
}

export function InfoOverlayRewardListDetail() {

    return (
        <HtmlTooltip placement="right-start" title={
            <div>
                <div style={{fontWeight: "bold", fontSize: "1.25em"}}>Show LPs details</div>
                <hr/>
                <p>
                    <strong>When enabled</strong>&#160;
                    the rewards and commissions are shown for all pools individually.
                </p>
                <p>
                    <strong>When disabled</strong>&#160;
                    the rewards and commissions from all pools are accumulated.<br/>
                    Since we accumulate different tokens values, we cannot show the quantity
                    of the containing tokens. That's only available when details is enabled.
                </p>
            </div>
        }>

            <div className="InfoOverlay">
                <HelpOutlineIcon color={"primary"} fontSize={"small"}/>
            </div>
        </HtmlTooltip>
    )
}


export function InfoOverlayHistoryIntern() {

    return (
        <HtmlTooltip placement="right-start" title={
            <div>
                <div style={{fontWeight: "bold", fontSize: "1.25em"}}>Show LPs details</div>
                <hr/>
                <p>
                    <strong>When enabled</strong>&#160;
                    internal TXs are shown.
                </p>
                <p>
                    <strong>When disabled</strong>&#160;
                    no internal TXs are shown
                </p>
            </div>
        }>

            <div className="InfoOverlay">
                <HelpOutlineIcon color={"primary"} fontSize={"small"}/>
            </div>
        </HtmlTooltip>
    )
}

