import React  from 'react';
import Export from '../components/Export'
import Address from '../components/Address'


export default function AccountHistory() {


    return (
        <div>
            <Address />
            <Export />
        </div>
    );
}
