import React, {useState} from 'react';
import KeyIcon from '@mui/icons-material/Key';
import {
    Alert, AlertTitle,
    Button, Collapse, Dialog, DialogActions, DialogContent,
    DialogTitle, IconButton, TextField, Tooltip
} from "@mui/material";

import {useConfigContext} from "../context/Config";


export default function AK() {
    const {ak: ak_org,  updateAK} = useConfigContext();
    const [ak, setAK] = useState<string|undefined>(ak_org);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);

    function handleOpen() {
        console.log("OPEN");
        setOpen(true);
        setAK(ak_org);
        setError(false);
    }

    const handleClose = () => {
        console.log("CLOSE");
        setOpen(false);
    };

    const handleSave = () => {
        console.log("SAVE");
        updateAK(ak)
            .then(() => handleClose())
            .catch((error) => { setAK(ak_org); setError(true);})
    };

    return (
        <>
            <IconButton onClick={handleOpen}>
                <Tooltip title="Change Access Key">
                    <KeyIcon
                        color={"primary"}
                        fontSize={"medium"}/>
                </Tooltip>
            </IconButton>
            {ak_org}
            <Dialog
                fullWidth={true}
                maxWidth={"xl"}
                open={open}
                onClose={handleClose}
                PaperProps={{sx: {position: 'fixed', top: 50, maxHeight: '80%'}}}
            >
                <DialogTitle>Access Key</DialogTitle>
                <DialogContent>
                    <DialogContent dividers>
                        <div className="GlobalSettings">
                            <TextField className="Setting AK_TextField"
                                       id="standard-name"
                                       label="Access Key"
                                       value={ak}
                                       onChange={(value) => setAK(value.currentTarget.value)}

                            />
                        </div>
                        <Collapse in={error}>
                            <Alert  severity="error" onClose={() => setError(false)}>
                                <AlertTitle>Invalid Access Key</AlertTitle>
                                <p>
                                    The Access Key is invalid!
                                </p>

                            </Alert>
                        </Collapse>
                    </DialogContent>
                </DialogContent>

                <DialogActions>
                    <Button autoFocus onClick={handleSave}>
                        Save changes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}