import React  from 'react';
import History from '../components/History'
import Address from '../components/Address'


export default function AccountHistory() {


    return (
        <div>
            <Address />
            <History />
        </div>
    );
}
