export interface Entry {
    header: string,
    content: string[],
    done?: string[]
}

export const ChangelogData: Entry[] = [
    {
        header: "v0.3.3- 2023-11-28",
        content: [
            "PFE Price Finding Engine",
            "Better price finding through different exchanges",
            "Prices for dTokens are from the DEX",
            "Detailed VAULT overview in the balance",
            "All dTokens are available",
            "Better history for all transactions",
        ]
    },
    {
        header: "v0.2.8- 2022-08-26",
        content: [
            "Local Google Fonds",
        ]
    },
    {
        header: "v0.2.7- 2022-08-01",
        content: [
            "dTokens for August 2022",
            "Adding stablepools",
            "When using DropDown filter for types on History/Rewards, filtering occurs " +
            "only after DropDown is closed",
            "Fixing some tokensplit pool",
            "Fixing history page failure",
        ]
    },
    {
        header: "v0.2.6- 2022-06-29",
        content: [
            "dTokens for June 2022",
        ]
    },
    {
        header: "v0.2.5- 2022-05-29",
        content: [
            "dTokens for Mai 2022",
        ]
    },
    {
        header: "v0.2.4- 2022-05-24",
        content: [
            "Adding FutureSwap Transactions",
            "FIX: Error that prevented some address from working properly",
        ]
    },
    {
        header: "v0.2.3 - 2022-04-29",
        content: [
            "dTokens for April 2022",
            "FIX: BarChart: Correct processing for reward and commission",
        ]
    },
    {
        header: "v0.2.2 - 2022-03-04",
        content: [
            "dTokens for March 2022 added",
            "Maximum precision for QTY in rewards CSV export",
            "Fixing some typos"
        ]
    },
    {
        header: "v0.2.1 - 2022-02-16",
        content: [
            "Realtime history and rewards",
            "Refactoring of rewards",
            "Refactoring of history",
            "Adopting different static pages (About, Home, Donate)"
        ]
    },
    {
        header: "v0.2.0 - 2022-01-19",
        content: [
            "Access Key features",
            "New Look&Feel of balance overview",
            "Grouping and Labeling",
            "Configuration Screen",
            "Vaults in balance overview",
            "Treemap view for debit & credit",
            "Localisation of for numbers",
            "Unlimited addresses",
            "Realtime balance data",
            "Show data block information",
            "Better differentiation of different dAssets in BarChart",
            "Show either value or quantity of your assets"
        ]
    },
    {
        header: "v0.1.5 - 2021-12-15",
        content: [
            "Optimising precision when displaying numbers",
            "Using maximum precision when using CSV export",
        ]
    },
    {
        header: "v0.1.4 - 2021-12-05",
        content: [
            "CSV export for reward and history",
            "Adding missing Tx types to history view",
            "Enabling remaining stocks and pools",
            "Switching to unique MUI components",
            "Design adjustments",
            "Adding YouTube channel",
        ]
    },
    {
        header: "v0.1.3 - 2021-11-27",
        content: [
            "Enabling stocks (TSLA, more to come) and DUSD, in Balance, Rewards and Diagrams",
            "New color schema for LP tokens"
        ]
    },
    {
        header: "v0.1.2 - 2021-11-23",
        content: [
            "Interface /adr/[DEFICHAIN_ADDRESS] to transmit address from other applications",
            "Percentage, number of tokens and total value shown in PieChart",
            "Interface improvements (switches, buttons for selecting reward type)",
            "BarChart: Filter for year and month. New aggregation by day"
        ]
    },
    {
        header: "v0.1.1 - 2021-11-19",
        content: [
            "Updated info about time it takes for newly added addresses to be shown",
            "BUG FIX: Pie chart values for DFI were not calculated correctly"
        ]
    },
    {
        header: "v0.1.0 - 2021-11-18",
        content: [
            "Bar diagram showing your rewards over time",
            "Pie chart visualizing your current balance",
            "Information overlays for better documentation of the various options",
            "Adding FAQ",
            "BUG FIX: address balance showed always 0 when been stocks where on that address"
        ]
    },
    {
        header: "v0.0.9 - 2021-11-10",
        content: [
            "Adding Rate field in reward detail view"
        ]
    },
    {
        header: "v0.0.8 - 2021-11-06",
        content: [
            "Simple & clean balance overview without LP & UTXO details. " +
            "The old view is still available as an option.",
        ]
    },
    {
        header: "v0.0.7 - 2021-11-05",
        content: [
            "Display LP tokens history (adding, removing and swapping)",
        ]
    },
    {
        header: "v0.0.6 - 2021-10-12",
        content: [
            "Display address balance with UTXOs, Tokens and LPs. Summarized and displayed " +
            "in selected fiat or crypto currency",
            "Multi Currency Support",
            "Usability improvements and alerts for better feedback",
            "Layout improvement",
            "Telegram channel added",
        ]
    },

    {
        header: "v0.0.5 - 2021-10-02",
        content: [
            "SSL enabled (HTTPS support)",
            "Allow multiple addresses",
            "Fix some layout issues on mobile devices",
        ]
    },
    {
        header: "v0.0.4 - 2021-10-01",
        content: [
            "Switching domain and name to dfi.tax (former dfi.to)",
            "New Layout",
            "Home, Changelog/Planed Features and About page added",
            "Validity check of DefiChain address",
        ]
    },
    {
        header: "v0.0.3 - 2021-09-28",
        content: [
            "Filter Type(CoinBase, Commission, Reward)",
            "Smoother layout",
            "Improved number format",
        ]
    },
    {
        header: "v0.0.2 - 2021-09-26",
        content: [
            "Masternode rewards are calculated",
            "Increased performance",
            "Aggregate by day, month, year",
            "Addresses uses for the first time are processed immediately (loading takes some time)"
        ]
    },
    {
        header: "v0.0.1 - 2021-09-17",
        content: [
            "Trying different node implementation"
        ]
    },
    {
        header: "alpha - 2021-09-02",
        content: [
            "First publishing on web server"
        ]
    }
];


