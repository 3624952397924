import {useEffect, useState} from "react";
import {MONTH, SERVER, YEARS} from "../global/Consts";
import {LOADING_SOURCE} from "../global/Enum";
import {useGlobalContext} from "../context/Global";
import {useConfigContext} from "../context/Config";
import {IDropDown, ITypeOptions} from "../global/Interface";
import {useStickyState} from "../global/Hooks";


export const YEAR: IDropDown[] = [
    ...YEARS().map((y) =>
        ({value: y, label: y.toString()}))
];

const TYPE_OPTIONS: ITypeOptions = {
    "COINBASE": {label: "COINBASE", active: true, enabled: true},
    "UTXO": {label: "UTXO", active: true, enabled: true},
    "AddPoolLiquidity": {label: "AddPoolLiquidity", active: true, enabled: true},
    "RemovePoolLiquidity": {label: "RemovePoolLiquidity", active: true, enabled: true},
    "PoolSwap": {label: "PoolSwap", active: true, enabled: true},
    "AccountToAccount": {label: "AccountToAccount", active: false, enabled: true},
    "AccountToUtxos": {label: "AccountToUtxos", active: false, enabled: true},
    "UtxosToAccount": {label: "UtxosToAccount", active: false, enabled: true},
    "AnyAccountsToAccounts": {label: "AnyAccountsToAccounts", active: false, enabled: true},
    "DepositToVault": {label: "DepositToVault", active: false, enabled: true},
    "WithdrawFromVault": {label: "WithdrawFromVault", active: false, enabled: true},
    "PaybackLoan": {label: "PaybackLoan", active: false, enabled: true},
    "MintToken": {label: "MintToken", active: false, enabled: true},
    "TakeLoan": {label: "TakeLoan", active: false, enabled: true},
    "AuctionBid": {label: "AuctionBid", active: false, enabled: true},
    "CloseVault": {label: "CloseVault", active: false, enabled: true},
    "ICXClaimDFCHTLC": {label: "ICXClaimDFCHTLC", active: false, enabled: true},
    "ICXSubmitDFCHTLC": {label: "ICXSubmitDFCHTLC", active: false, enabled: true},
    "ICXSubmitEXTHTLC": {label: "ICXSubmitEXTHTLC", active: false, enabled: true},
    "ICXMakeOffer": {label: "ICXMakeOffer", active: false, enabled: true},
    "ICXCreateOrder": {label: "ICXCreateOrder", active: false, enabled: true},
    "ICXCloseOrder": {label: "ICXCloseOrder", active: false, enabled: true},
    "DFIP2203": {label: "DFIP2203", active: false, enabled: true},
    "FutureSwapExecution": {label: "FutureSwapExecution", active: false, enabled: true},
    "TokenSplit": {label: "TokenSplit", active: false, enabled: true},
};

interface ISetting {
    year: number,
    month: number,
    types: ITypeOptions,
    intern: boolean,
}

const DEFAULT_SETTING: ISetting = {
    year: YEAR[0].value,
    month: MONTH[0].value,
    types: TYPE_OPTIONS,
    intern: true,
}

export interface IHistory {
    id?: number,
    adr: string,
    tx_id: string,
    blk_id: number,
    dt: string,
    cat: string,
    tx_value: number,
    fee_qty: number,
    fee_value: number,
    tokens: IToken[],
}

export interface IToken {
    adr: string,
    intern: boolean,
    utxo: boolean,
    code: string,
    qty: number,
    value_bridge: number,
    value: number
}

export interface IHistoryIntern {
    id: number,
    id_tx: number,
    path?: string[],
    blk_id?: number,
    dt?: string,
    tx_id?: string,
    adr?: string,
    cat?: string,
    tx_value?: number,
    fee_qty?: number,
    fee_value?: number,
    code?: string,
    qty?: number,
    intern?: boolean,
    utxo?: boolean,
    value_bridge?: number,
    value?: number
}


export interface IHistoryState {
    min_blk_id: number,
    min_blk_dt: string,
    max_blk_id: number,
    max_blk_dt: string,
}


export interface IHistorySheet extends IHistoryState {
    history: IHistory[]
}

export function useHistory() {
    const {setLoading, isLoading, setError} = useGlobalContext();
    const {ak, config} = useConfigContext();
    const [historySheet, setHistorySheet] = useState<IHistorySheet>();
    const [historyState, setHistoryState] = useState<IHistoryState>();
    const [data, setData] = useState<IHistoryIntern[]>([]);
    const [setting, setSetting] = useStickyState<ISetting>(DEFAULT_SETTING, "SETTING_HISTORY")


    // check if there is a new DEFAULT_SETTING and local storage has to be updated
    useEffect(() => {
        for (let key in DEFAULT_SETTING.types) {
            if (setting.types[key] == null) {
                console.log("Haven't found '", key, "' in SETTINGS_HISTORY. Setting to Default")
                setSetting(DEFAULT_SETTING);
                break;
            }
        }
    }, []);


    useEffect(() => {
        if (!ak || isLoading(LOADING_SOURCE.LOAD_HISTORY))
            return;

        const url = `${SERVER}/hst/${ak}/${setting.year}/${setting.month}`;
        console.log(url);
        setLoading(true, LOADING_SOURCE.LOAD_HISTORY);
        fetch(url)
            .then(result => result.json())
            .then(value => {
                setSetting((set) => {
                    Object.keys(set.types).forEach((type) => {
                        set.types[type].enabled = value.history.some((res: { cat: string }) => res.cat === type)
                    })
                    return {...set, types: set.types};
                });
                setHistoryState(value);
                setHistorySheet(value);
            }).catch(() => setError())
            .finally(() => setLoading(false, LOADING_SOURCE.LOAD_HISTORY))

    }, [ak, config, setting.year, setting.month]);


    useEffect(() => {
        if (!historySheet)
            return;

        let result_filtered = historySheet.history
            .filter((ele: { cat: string }) => setting.types[ele.cat]?.active ?? false);

        let row_idx = 0;
        let row_idx_tx = 0;
        let data: IHistoryIntern[] = [];
        result_filtered.forEach((entry) => {
            let hst: IHistoryIntern = {
                id: row_idx++,
                id_tx: ++row_idx_tx,
                // in case of empty tx_id use blk_id (FutureSwapExecution)s
                path: [entry.tx_id ?? entry.blk_id],
                blk_id: entry.blk_id,
                dt: entry.dt,
                tx_id: entry.tx_id,
                tx_value: entry.tx_value,
                fee_qty: entry.fee_qty > 0 ? entry.fee_qty : undefined,
                fee_value: entry.fee_value > 0 ? entry.fee_value : undefined,
                cat: entry.cat,
                value: entry.tx_value,
            }
            data.push(hst);
            entry.tokens
                .sort((a, b) => b.qty - a.qty)
                .forEach((token, tkn_idx) => {

                    let hst: IHistoryIntern = {
                        id: row_idx++,
                        id_tx: row_idx_tx,
                        // in case of empty tx_id use blk_id (FutureSwapExecution)
                        path: [entry.tx_id ?? entry.blk_id, tkn_idx.toString()],
                        blk_id: entry.blk_id,
                        dt: entry.dt,
                        //tx_id: entry.tx_id,
                        //tx_value: entry.tx_value,
                        //fee_qty: entry.fee_qty,
                        //fee_value: entry.fee_value,
                        //cat: entry.cat,
                        adr: token.adr,
                        code: token.code,
                        intern: token.intern,
                        utxo: token.utxo,
                        qty: token.qty,
                        value_bridge: token.value_bridge,
                        value: token.value,
                    }
                    if (!(!setting.intern && token.intern))
                        data.push(hst);
                })
        });
        setData(data);
    }, [historySheet, setting.types, setting.intern])


    return {setting, dataNew: data, historyState, setSetting};
}