import React from 'react';

export default function Donate() {

    return (
        <div>
            <div className="Box">
                <div className="BoxHeader Headline">
                    Support & Donate
                </div>
                <div className="BoxContent TEXT_2">
                    <div className="HEADING_1">Supporting DFI.tax?</div>
                    <div className="BLOCK_1">
                        DFI.tax is a community project and got its
                        &#160;<a href="https://github.com/DeFiCh/dfips/issues/78" target="_blank">CFP 2111-05</a>&#160;
                        approved in Nov 2021. <br/>
                        With that funding it is currently possible to cover all costs that arise operating DFI.tax.
                        <p>
                            If you want to further support the developer (that would be me) you can support me with
                            sending DFI or any other dToken to:
                            <p>
                                <strong>df1qprq0pu595qpmz4s4mkg0xaegxef3ux5n2s0kg5</strong>
                            </p>
                            Any support will be greatly appreciated!
                        </p>

                    </div>
                    <div className="HEADING_1">Affiliate</div>
                    <div className="BLOCK_1">
                        additionally you can support we with using the following affiliate links where I'll get
                        a small commission when you use it, having no downside for you.
                        <p>
                            <a href="https://dfx.swiss/app?code=004-929" target="_blank">DFX.swiss</a> 004-929
                        </p>
                        <p>
                            <a href="https://cakedefi.com/?ref=706283" target="_blank">CakeDefi</a> 706283
                        </p>
                        <p>
                            <a href="https://bittrex.com/discover/join?referralCode=A2I-H0O-P03" target="_blank">Bittrex.com</a> A2I-H0O-P03
                        </p>
                        <p>
                            <a href="https://accounts.binance.com/en/register?ref=65024891" target="_blank">Binance.com</a> 65024891
                        </p>
                        <p>
                            <a href="" target="_blank"></a>
                        </p>
                        <p>
                            <a href="" target="_blank"></a>
                        </p>
                        <p>
                            <a href="" target="_blank"></a>
                        </p>
                    </div>
                </div>
                <div className="BoxFooter"/>
            </div>
        </div>

    );
}
