import React from 'react';
import {ChangelogData, Entry} from "../data/ChangelogData";
import {FeaturesData} from "../data/FeaturesData";

export default function Changelog() {
    return (
        <div className="FlexOne">
            <div>
                <div className="Box">
                    <div className="BoxHeader Headline">
                        Changelog
                    </div>
                    {performData(ChangelogData)}
                    <div className="BoxFooter"/>
                </div>
            </div>
            <div>
                <div className="Box">
                    <div className="BoxHeader Headline">
                        Planned Features
                    </div>
                    {performData(FeaturesData)}
                    <div className="BoxFooter"/>
                </div>
            </div>
        </div>
    );
}


function performData(data: Entry[]) {
    return (
        <div className="BoxContent">
            {
                data.map((entry) => (
                    <div>
                        <div className="ChangelogGroup">
                            {entry.header}
                        </div>
                        <ul>
                            {entry.content.map((item) => (
                                <li>{item}</li>
                            ))}
                        </ul>
                        <hr hidden={entry.header === data[data.length - 1].header} />
                    </div>
                ))
            }
        </div>
    );
}