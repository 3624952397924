import * as React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useConfigContext} from "../context/Config";
import {useEffect, useRef} from "react";


type Params = {
    adr?: string;
    ak?: string
};


export default function Params() {
    const {ak, config, updateAK, updateConfig} = useConfigContext();
    const {adr, ak: ak_new} = useParams<Params>();
    const initalRender = useRef(true);

    let history = useNavigate();

    useEffect(() => {
        if (ak_new && ak_new !== ak)
            updateAK(ak_new).then(() => history("/diagram")
            ).catch(() => history("/"));
        else if (ak_new)
            history("/diagram");
    }, [])


    useEffect(() => {
        if (initalRender.current) {
            initalRender.current = false;
            return;
        }

        if (adr
            && !config?.addresses.find((a) => a.value === adr)
            && adr.match(/^[a-z0-9]{34,42}$/i))
            updateConfig({
                ...config!,
                addresses: config!.addresses.concat({value: adr, enabled: true})
            }).then(() => history("/diagram"))
                .catch(() => history("/"));
        else if (adr)
            history("/diagram")
    }, [config])

    return (<></>);
}